<script lang="ts">
    import { pwaInfo } from 'virtual:pwa-info';
    import { onMount } from 'svelte';
    import { PUBLIC_PWA_DISABLED } from '$env/static/public';

    const isPwaEnabled = PUBLIC_PWA_DISABLED !== 'true' && pwaInfo

    let ReloadPromptWrapper: any; // PWA 리로드를 체크하는 프롬프트
    onMount(async () => {
        isPwaEnabled && (ReloadPromptWrapper = (await import('./ReloadPromptWrapper.svelte')).default);
    });

    $: webManifest = isPwaEnabled ? pwaInfo.webManifest.linkTag : '';

</script>

<svelte:head>
    {@html webManifest}
</svelte:head>


<!-- PWA 리로드 프롬프트 -->
{#if ReloadPromptWrapper}
    <svelte:component this={ReloadPromptWrapper} />
{/if}
